// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .warrantyClaimsContainer {
    padding: 32px 44px;
    font-family: var(--font-family);
    color: var(--black-text-color);
}

#new-dol .warrantyClaimsHeader {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: var(--main-text-color);
    margin-top: 0;
    margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/warrantyClaims/warrantyClaims.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,+BAA+B;IAC/B,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".warrantyClaimsContainer {\n    padding: 32px 44px;\n    font-family: var(--font-family);\n    color: var(--black-text-color);\n}\n\n.warrantyClaimsHeader {\n    font-weight: 500;\n    font-size: 32px;\n    line-height: 40px;\n    color: var(--main-text-color);\n    margin-top: 0;\n    margin-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
