import { useAppError } from 'common/appErrorContext';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useStatus } from 'common/statusContext';
import { Spinner } from 'components/spinner/Spinner';
import { ErrorPopup } from 'pages/creator/popup/ErrorPopup.component';
import React, { useEffect } from 'react';
import './warrantyClaims.css';
import { HorizontalLine } from 'pages/specialOffer/horizontalLine/HorizontalLine';
import { Label } from 'components/label/Label.component';
import { Button } from 'components/button/Button.component';
import { ClaimsListSection } from './warrantyList/WarrantyListSection';
export var WarrantyClaims = withCancellation(function () {
    var _a = useStatus(), status = _a.status, setStatus = _a.setStatus;
    var _b = useAppError(), appError = _b.appError, setAppError = _b.setAppError;
    function isErrorPopup() {
        return (appError === null || appError === void 0 ? void 0 : appError.redirect) === 'errorPopup';
    }
    useEffect(function () {
        return function () {
            setAppError(undefined);
        };
    }, []);
    function handleSetAppError(newAppError) {
        setAppError(newAppError);
    }
    // const clearFiltersDisabled = !selectedFilters;
    var clearFiltersDisabled = false;
    function clearAllSelectedFilters() {
        return;
    }
    var searchIsDisabled = false;
    function onSearchClick() {
        return;
    }
    return (React.createElement(React.Fragment, null,
        status === 'pending' && React.createElement(Spinner, null),
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'warrantyClaimsContainer' },
                React.createElement("h2", { className: 'warrantyClaimsHeader' }, "List of warranty claims"),
                React.createElement("div", { className: 'warrantyClaimsFiltersWrapper' },
                    React.createElement("div", null,
                        React.createElement(Label, { bold: true }, "Repair dates (from - to):"),
                        React.createElement("div", null, "dropdown")),
                    React.createElement("div", null,
                        React.createElement(Label, { bold: true }, "Status:"),
                        React.createElement("div", null, "dropdown")),
                    React.createElement("div", null,
                        React.createElement(Label, { bold: true }, "Vehicle VIN:"),
                        React.createElement("div", null, "input")),
                    React.createElement(HorizontalLine, { color: "#D5D5D5" }),
                    React.createElement("div", { className: "filteringActionsWrapper" },
                        React.createElement("div", { className: "filtersSelectedWrapper" },
                            React.createElement(Label, null, "Selected status:"),
                            React.createElement("div", { className: "filtersChips" })),
                        React.createElement("div", { className: "searchClaimsButtonsWrapper" },
                            React.createElement("div", { className: "searchClaimsButtons" },
                                React.createElement("div", { className: "clearFilters ".concat(clearFiltersDisabled ? 'disabled' : '') },
                                    React.createElement("button", { className: 'clearFiltersButton', type: 'button', onClick: clearAllSelectedFilters }, "Clear filters")),
                                React.createElement("div", { className: "searchClaimsButtonWrapper" },
                                    React.createElement(Button, { disabled: searchIsDisabled, onClick: onSearchClick }, "Search CLAIMS"))))),
                    React.createElement("div", { className: "claimsListHeader" },
                        React.createElement("div", { className: "claimsAmount", "data-testid": 'total-claims' }, "Number of claims (65)"),
                        React.createElement("div", { className: "claimsCommonExpandinWrapper", "data-testid": 'sorting-wrapper' },
                            React.createElement("div", { className: "commonExpandingLabel" }, "Expand claims"),
                            React.createElement("div", null, "switch"))),
                    React.createElement(ClaimsListSection, null))),
            isErrorPopup() ? React.createElement(ErrorPopup, { text: appError.errorDesc }) : null)));
});
